var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--35",attrs:{"align":"start"}},[_c('v-col',{attrs:{"md":"8","cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"section-title text-left mb--50 mb_sm--30 mb_md--30"},[_c('h2',{staticClass:"heading-title"},[_vm._v("Let's talk!")]),_c('p',{staticClass:"description"},[_vm._v(" We have the resources and experience. Tell us what you are looking for. ")])]),(!_vm.submitted && !_vm.submitError)?_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","placeholder":"Your Name *"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"text","rules":"required|email","placeholder":"Your email *"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.phone),expression:"formData.phone"}],attrs:{"type":"text","placeholder":"Your phone *"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "phone", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],attrs:{"placeholder":"In a few words, describe what you are looking for."},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"rn-button-style--2 btn_solid",attrs:{"type":"submit","value":"submit"}},[_vm._v(" "+_vm._s(_vm.submitButton)+" ")])],1)]}}],null,false,3760972927)})],1):(_vm.submitted && !_vm.submitError)?_c('div',[_vm._v(" Thank you for your message! We'll be in touch soon! ")]):_c('div',[_vm._v(" Error sending your message. Please try again later or email us directly at "),_c('a',{attrs:{"href":"mailto:hello@bemagnified.co"}},[_vm._v("hello@bemagnified.co")]),_vm._v("! ")])]),_c('v-col',{staticClass:"d-md-block d-none",attrs:{"offset-md":"1","md":"3","cols":"12","order":"1","order-md":"2"}},[_c('div',{staticClass:"mt--50 mb_md--40 mb_sm--40"},[_c('img',{attrs:{"src":require("../../assets/images/contact/plan.png")}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }